/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueNativeNotification from "vue-native-notification";
import 'vue-plyr/dist/vue-plyr.css'
import VueMeta from 'vue-meta'

import VueLazyLoad from "vue-lazyload";

Vue.use(VueLazyLoad);
Vue.use(VueNativeNotification, {
  requestOnNotify: true,
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(require('vue-moment'));
Vue.config.productionTip = false;

export const baseUrl = "https://api.postaam.com/api/v1/";
// export const baseUrl = "http://localhost:5002/api/v1/"; 
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
