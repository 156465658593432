import Frontend from "../views/user/Frontend";
const frontendmenu = [
  {
    path: "/",
    component: Frontend,
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
        import(/* webpackChunkName: "Gig" */ "../views/user/views/gig/GigApply"),
      },
      
      {
        path: "/gig",
        name: "GigApply",
        component: () =>
          import(/* webpackChunkName: "Gig" */ "../views/user/views/gig/GigApply"),
      },
      {
        path: "/giglist",
        name: "Gig",
        component: () =>
          import(/* webpackChunkName: "Gig" */ "../views/user/views/gig/Gigs"),
      },
      {
        path: "/gig/:id",
        name: "GigSingle",
        component: () =>
          import(/* webpackChunkName: "GigSingle" */ "../views/user/views/gig/GigSingle"),
      },
      {
        path: "/gigApplied",
        name: "GigApplied",
        component: () =>
          import(/* webpackChunkName: "GigSingle" */ "../views/user/views/gig/GigApplied"),
      },
      {
        path: "/gigAppliedSingle/:id",
        name: "GigAppliedSingle",
        component: () =>
          import(/* webpackChunkName: "GigAppliedSingle" */ "../views/user/views/gig/GigAppliedSingle"),
      },
      {
        path: "/gigback",
        name: "GigBack",
        component: () =>
          import(/* webpackChunkName: "GigAppliedSingle" */ "../views/user/views/gig/Gigback"),
      },

       {
        path: "/verification",
        name: "VerificationPage",
        component: () =>
          import(/* webpackChunkName: "VerificationPage" */ "../views/user/views/verification/VerificationPage"),
      },

      
    ],
  },
];

export default frontendmenu;
