import axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
    gig: undefined,
    gigthread: undefined,
    gigs: [],
    gigRating: undefined,
});

const getters = {
    allGigs: (state) => state.gigs,
    allGig: (state) => state.gig,
    allGigThread: (state) => state.gigthread,
    allGigRating: (state) => state.gigRating,
};

const actions = {
    async postGigContentThread({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "gig/postContentThread", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGigThread", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async postGigContent({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "gig/postContent", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGig", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async postGigApply({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "gig/apply", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGig", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getGigs({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "gig/gigListUser", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGigs", response.data);
            }
        } catch (error) {
            console.log(error);
            router.push("/login/user");
            localStorage.clear();
        }
        
    },
    async getGigsApplied({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "gig/gigAppliedListUser", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGigs", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getGig({ commit }, data) {
        try {
            const response = await axios.get(baseUrl + "gig/singleGig/" + data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGig", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getGigApplied({ commit }, data) {
        try {
            const response = await axios.get(baseUrl + "gig/gigSingleApplied/" + data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGigThread", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async postToPost({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "gig/postToPost", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGig", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async postForApproval({ commit }, data) {
        try {
            const response = await axios.get(baseUrl + "gig/gigApplyForApproval/"+ data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGig", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async postBusinessRating({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "reviewrating/rateBusiness", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGigRating", response);
            }
        } catch (error) {
            console.log(error);
            commit("setGigRating", error.response);
        }
    },
    async postGigRating({ commit }, data) {
        try {
            const response = await axios.post(baseUrl + "reviewrating/rateGig", data, {
                headers: {
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setGigRating", response);
            }
        } catch (error) {
            console.log(error);
            commit("setGigRating", error.response);
        }
    },
};

const mutations = {
    setGig: (state, data) => (state.gig = data),
    setGigs: (state, data) => (state.gigs = data),
    setGigThread: (state, data) => (state.gigthread = data),
    setGigRating: (state, data) => (state.gigRating = data),
};

export default { state, getters, actions, mutations };
