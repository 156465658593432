import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
    product: undefined,
    products: undefined,
    useitwith: undefined,
    carts: [],
    checkouts: [],
    shippingAddress: undefined,
    billingAddress: undefined,
    addresses: [],
    payments: [],
    contents:[],
    content:undefined
});

const getters = {
    allProduct: (state) => state.product,
    allProducts: (state) => state.products,
    allUseitwith: (state) => state.useitwith,
    allCarts: (state) => state.carts,
    allCheckouts: (state) => state.checkouts,
    allAddress: (state) => state.addresses,
    allPayments:(state)=>state.payments,
    allContents:(state)=>state.contents,
    allSelectContent:(state)=>state.content
};

const actions = {
    async selectContent({commit},data){
        try{
            commit("selectSingleContent",data);
        }catch(error){
            console.log(error);
        }
    },
    async productFetch({ commit }, data) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.post(baseUrl + "product/allProductSimilarFrontend", data, {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            } else {
                response = await Axios.post(baseUrl + "post/posts", data);
            }

            if (response.status == 200) {
                commit("setAllProduct", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async productUseFetch({ commit }, data) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.post(baseUrl + "product/allProductUseItWithFrontend", data, {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            } else {
                response = await Axios.post(baseUrl + "post/posts", data);
            }

            if (response.status == 200) {
                commit("setAllUseProduct", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },

    async singleProduct({ commit }, data) {
        try {
            let response = await Axios.get(baseUrl + "product/getBasicProductById/" + data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });

            if (response.status == 200) {
                commit("setSingleProduct", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },

    async cartFetch({ commit }) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.get(baseUrl + "cart/listofcart", {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            }

            if (response.status == 200) {
                commit("setCarts", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },

    async checkOutFetch({ commit }) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.get(baseUrl + "checkout/listofcheckout", {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            }

            if (response.status == 200) {
                commit("setCheckouts", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async cartAction({ commit }, data) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.post(baseUrl + "cart/create", data, {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            }

            if (response.status == 200) {
                commit("setCarts", response.data);

            }
        } catch (error) {
            console.log(error);
        }
    },
    async checkoutAction({ commit }, data) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.post(baseUrl + "checkout/create", data, {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            }

            if (response.status == 200 || response.status == 201) {
                commit("setCheckouts", response.data);
            }
        } catch (error) {
            console.log(error);
            commit("setCheckouts", error.response);
        }
    },
    async getContents({ commit }, data) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.post(baseUrl + "product/allProductFrontend", data, {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            }

            if (response.status == 200 || response.status == 201) {
                commit("setContents", response.data);
            }
        } catch (error) {
            console.log(error);
            commit("setContents", error.response);
        }
    },
    async getPayments({ commit }) {
        try {
            let response = await Axios.get(baseUrl + "payments/paymentList");
            if (response.status == 200) {
                commit("setPayments", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteCart({commit},data){
        try{
            let response = await Axios.delete(baseUrl+`cart/singleCart/${data}`,{
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if(response.status == 200){
                commit("setCarts",response.data.results);
            }
        }catch(error){
            console.log(error);
        }
    },
    async productSearch({commit},data){
        try{
            const response = await Axios.post(baseUrl+`product/searchProduct`,data);
            if(response.status == 200){
                commit("setAllProduct",response.data);
            }
        }catch(error){
            console.log(error);
        }
    }

};

const mutations = {
    setAllProduct: (state, data) => {
        state.products = data;
    },
    setAllUseProduct: (state, data) => {
        state.useitwith = data;
    },
    setSingleProduct: (state, data) => {
        state.product = data;
    },
    setCarts: (state, data) => {
        state.carts = data;
    },
    setCheckouts: (state, data) => {
        state.checkouts = data;
    },
    setPayments: (state, data) => {
        state.payments = data;
    },
    setContents:(state,data)=>{
        state.contents = data
    },
    selectSingleContent:(state,data)=>{
        state.content = data
    }
};

export default { state, getters, actions, mutations };
