import Authorization from "../views/authorization/Authorization";
const authorization = [
  {
    path: "/login",
    component: Authorization,
    children: [
      {
        path: "/login/:slug",
        name: "Login",
        component: () =>
          import(
            /* webpackChunkName: "Login" */ "../views/authorization/views/Login"
          ),
      },
   
      {
        path: "/verify/:slug",
        name: "Verify",
        component: () =>
          import(
            /* webpackChunkName: "Verify" */ "../views/authorization/views/Verify"
          ),
      },
      {
        path: "/registration/:slug",
        name: "Register",
        component: () =>
          import(
            /* webpackChunkName: "Register" */ "../views/authorization/views/Register"
          ),
      },
      {
        path: "/forgetPassword/:slug",
        name: "Register",
        component: () =>
          import(
            /* webpackChunkName: "Register" */ "../views/authorization/views/ForgetPassword"
          ),
      },   
      {
        path: "/verify-otp/:slug",
        name: "Register",
        component: () =>
          import(
            /* webpackChunkName: "Register" */ "../views/authorization/views/VerifyOTP"
          ),
      },
      {
        path: "/resetPassword/:slug",
        name: "ResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "Register" */ "../views/authorization/views/ResetPassword"
          ),
      },
    ],
  },
];

export default authorization;
