import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";
import store from "..";

const state = () => ({
  categories: [],
  notification: [],
  notifications: [],
  message: undefined,
  
});

const getters = {
  allCategory: (state) => state.categories,
  allNotifications: (state) => state.notification,
};

const actions = {
  async singleNotificationView({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "notification/view/"+
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      commit("setNotificationBell", response.data);
    
    } catch (error) {
      console.log(error);
    }
  },  

  async getNotificationList({ commit }, data) {
    try {
    
      const response = await Axios.post(
        baseUrl + "notification/listOfNotification",
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      commit("setNotification", response.data);
    } catch (error) {
      console.log(error);
    }
  },  
 
  async registerDevice({ commit }, data) {
    try {
    
      const response = await Axios.post(
        baseUrl + "notification/register",
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      commit("setNotification", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getNotificationHistory({ commit }, data) {
    try {
    
      const response = await Axios.post(
        baseUrl + "notification/listOfNotificationSentHistory",
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      commit("setNotification", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async addNotification({ commit }, data) {
    try {
    
      const response = await Axios.post(
        baseUrl + "notification/createNotification",
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        commit("setNotification", response.data.results);
        router.push("/dashboard/notification");
      }
    } catch (error) {
      console.log(error);
    }
  },
  async sendNotification({ commit }, data) {
    try {
    
      const response = await Axios.post(
        baseUrl + "notification/sendnotification",
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        store.commit("setMessage", response.data.message);
        commit("set");
      }
    } catch (error) {
      console.log(error);
      store.dispatch("setMessage", error.data.message);
    }
  },
};

const mutations = {
  setCategory: (state, menu) => (state.categories = menu),
  setNotification: (state, data) => (state.notification = data),
  setMessage: (state, data) => (state.message = data),
  setNotificationBell: (state, data) => (state.message = data),
};

export default { state, getters, actions, mutations };
