import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import notification from "./modules/notification";
import post from "./modules/post";
import product from "./modules/product";
import popup from "./modules/popup";
import order from "./modules/order";
import gig from "./modules/gig";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    notification,
    post,
    product,
    popup,
    order,
    gig
  },
});
