<template>
  <v-app id="app">
    <router-view :key="$route.fullPath"></router-view>
  </v-app>
</template>
<script>
// import Axios from "axios";
// import { baseUrl } from "./main";
export default {
  name: "App",
  data: () => ({
    notificationData: {
      deviceToken:
        "cdpOu3H7ThCtqSuOJD47lp:APA91bGkTN7Wnt71f1zBPipJYHHEs1J89b9hPgkCXfMXVHy-oMMFEE7ZrRFXNlNwJ0C73DnyZRkRcieBfyZRo6vGKcS3IXdJJZSprFBhuUwLeiedHZF0YFJzsOGRc8qYHjKtZNrNVadJ",
      notificationFor: "user",
    },
  }),

};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.filterSearch {
  
  width: 106%;
  .v-text-field__details{
    display: none;
  }
  fieldset {
    background: #fff;
  }
}
.slick-dots {
  top: 82% !important;
  text-align: center !important;
  @media only screen and (min-width: 1904px) {
    left: 50%;
  }

  li {
    &.slick-active {
      margin: 0;
    }
    width: 12px;
    margin: 0px !important;
    button {
      &:before {
        position: absolute;
        top: 0 !important;
        left: 0;
        content: "•";
        width: 10px;
        height: 20px;
        font-family: "slick";
        font-size: 12px;
        line-height: 12px !important;
        text-align: center;
        margin: 0px !important;
        color: white !important;
        opacity: 1 !important;
      }
    }
  }
}

.slick-active:hover {
  margin: 0;
  button {
    color: white;
    &:before {
      transition: 0.8s ease-out;
      top: 2px !important;
      font-size: 2px !important;
      text-align: center;
      color: transparent !important;
      border-radius: 20px !important;
      background: white !important;
      width: 20px;
      height: 6px;
    }
  }
}

.slick-dotted {
  margin-bottom: 0 !important;
}

.slick-prev,
.slick-next {
  &:before {
    color: green !important;
  }
}
</style>
