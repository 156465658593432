import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  orders: [],
  order: undefined,
  sales:[]
});

const getters = {
  allOrders: (state) => state.orders,
  allSingleOrder: (state) => state.order,
  allSales:(state)=>state.sales
};

const actions = {
  async createOrder({commit},data){
    try{
      const response = await Axios.get(baseUrl+"oreder/createOrder", {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });

      if(response.status == 201){
        commit("setOrder",data)
      }
    }catch(error){
      console.log(error);
    }
  },
  async getOrders({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "order/listoforders",
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      commit("setOrders", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleOrder({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + `order/singleOrder/${data}`,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      commit("setOrder", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSales({commit},data){
    try{
      const response = await Axios.post(
        baseUrl + `order/sales`,data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      commit("setSales", response.data);
    }catch(error){
      console.log(error);
    }
  }
};

const mutations = {
  setOrders: (state, data) => (state.orders = data),
  setOrder: (state, order) => (state.order = order),
  setSales: (state, data) => (state.sales = data),
};

export default { state, getters, actions, mutations };
