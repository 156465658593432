const state = () => ({
    popStatus: false,
});

const getters = {
    allPopStatus: (state) => state.popStatus,
};

const actions = {
    async popupAction({ commit }, data) {
        commit("setPopupStatus", data);
    },
};

const mutations = {
    setPopupStatus: (state, menu) => (state.popStatus = menu),
};

export default { state, getters, actions, mutations };
