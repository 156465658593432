import Vue from "vue";
import VueRouter from "vue-router";
import authorization from "./authorization";
import user from "./user";

Vue.use(VueRouter);

const routes = [...authorization,...user];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router;
