import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
    posts: undefined,
    postlists: [],
    tag: undefined,
    postView: undefined,
    singlePost: undefined,
    searches: [],
    images: [],
    imageUrls: [],
    videos: [],
    popImage: false,
    bid: undefined,
    bidders: [],
    tags: [],
    populartags: [],
    categories: [],
});

const getters = {
    allPost: (state) => state.posts,
    allPopularTags: (state) => state.populartags,
    allSingleTag: (state) => state.tag,
    allSinglePost: (state) => state.postView,
    allPostSingle: (state) => state.singlePost,
    allSearches: (state) => state.searches,
    allImages: (state) => state.images,
    allImageUrls: (state) => state.imageUrls,
    allVideos: (state) => state.videos,
    allPopImage: (state) => state.popImage,
    allSingleBid: (state) => state.bid,
    allBidders: (state) => state.bidders,
    allTags: (state) => state.tags,
    allCategories: (state) => state.categories
};

const actions = {
    async postFetch({ commit }, data) {
        try {
            let response;
            if (localStorage.getItem("token")) {
                response = await Axios.post(baseUrl + "post/postsWithToken", data, {
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
            } else {
                response = await Axios.post(baseUrl + "post/posts", data);
            }

            if (response.status == 200) {
                commit("setAllPost", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async popularTags({ commit }) {
        try {
            let response = await Axios.get(baseUrl + "tags/populartags");
            if (response.status == 200) {
                commit("setAllPopularTags", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async singleTag({ commit }, data) {
        try {
            let response = await Axios.post(baseUrl + "tags/singleTagPostViewWithToken", data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });

            if (response.status == 200) {
                commit("setSingleTag", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async singlePostView({ commit }, data) {
        try {
            let response = await Axios.post(baseUrl + "post/singlePostViewWithToken", data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });

            if (response.status == 200) {
                commit("setSinglePostView", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async singlePost({ commit }, data) {
        try {
            let response = await Axios.get(baseUrl + "post/singlePostWithToken/" + data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });

            if (response.status == 200) {
                commit("setSinglePostView", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async postLike({ commit }, data) {
        try {
            let response = await Axios.post(baseUrl + "post/singlePostLike", data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });

            if (response.status == 200) {
                this.state.posts.forEach((ele) => {
                    if (data.userHandle == ele.userHandle) {
                        ele.isFollowed = true;
                    }
                });
                commit("setSinglePost", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSearches({ commit }, data) {
        try {
            let response = await Axios.post(baseUrl + "product/searchWithPost ", data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });

            if (response.status == 200) {

                commit("setSearch", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSearchesHistory({ commit }) {
        try {
            let response = await Axios.get(baseUrl + "product/listofsearchhistory ", {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 201) {
                commit("setSearch", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async createPost({ commit }, data) {
        try {
            const response = await Axios.post(baseUrl + "post/create", data,{
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 201 || response.status == 200) {
                let dat = {postId:response.data.results._id}
                let res = Axios.post(baseUrl + "post/finalSubmit", dat,{
                    headers: {
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                    },
                });
                commit("setPost", res.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deletePost({ commit }, data) {
        try {
            let response = await Axios.delete(`${baseUrl}post/delete/${data}`);
            if (response.status == 200) {
                commit("setAllPost", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async applyBidding({ commit }, data) {
        try {
            let response = await Axios.post(`${baseUrl}auction/create`, data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 201) {
                commit("setSingleBid", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBidders({ commit }, data) {
        try {
            let response = await Axios.post(`${baseUrl}auction/bidders`, data, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
                },
            });
            if (response.status == 200) {
                commit("setBidders", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    setPopImageStatus: ({ commit }, data) => {
        commit("setPopImageStatusMut", data);
    },
    setImages: ({ commit }, data) => {
        commit("setImages", data);
    },
    setVideoUrl: ({ commit }, data) => {
        commit("setVideoUrlMut", data);
    },
    setTags: ({ commit }, data) => {
        commit("setTagsMut", data);
    },
    removeTags: ({ commit }, data) => {
        commit("removeTagsMut", data);
    },
    async getCategories({ commit }) {
        try {
            const response = await Axios.get(baseUrl + 'category/categorySelects');
            commit("setCategories", response.data);
        } catch (error) {
            console.log(error);
        }
    }
};

const mutations = {
    setAllPost: (state, data) => {
        state.posts = data;
    },
    setAllTags: (state, data) => {
        state.tags = data;
    },
    setAllPopularTags: (state, data) => {
        state.populartags = data;
    },
    setAllPosts: (state, data) => {
        state.posts = data;
    },
    setSingleTag: (state, data) => {
        state.tag = data;
    },
    setSinglePostView: (state, data) => {
        state.postView = data;
    },
    setSinglePost: (state, data) => {
        state.singlePost = data;
    },
    setSearch: (state, data) => {
        state.searches = data;
    },
    setPost: (state, data) => {
        state.singlePost = data;
    },
    setPopImageStatusMut: (state, data) => (state.popImage = data),
    setImages: (state, data) => {
        let imageName = "postaam.jpeg";
        state.images.push(data.imageUrl);
        fetch(data.imageUrl)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (ele) {
                return new File([ele], imageName, { type: `image/jpeg` });
            })
            .then(async function (file) {
                state.imageUrls.push(file);
        });
    },
    setVideoUrlMut: (state, data) => {
        state.videos.push(data)
    },
    setSingleBid: (state, data) => {
        state.bid = data;
    },
    setBidders: (state, data) => {
        state.bidders = data;
    }, 
    setTagsMut: (state, data) => state.tags.push(data),
    removeTagsMut: (state, data) => state.tags.splice(data, 1),
    setCategories: (state, data) => state.categories = data,

};

export default { state, getters, actions, mutations };
