import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  logins: [],
  registrations: [],
  verifications: [],
  loadingAuth: false,
  affiliation: [],
  users: [],
  dashboard: [],
  csrfToken: undefined,
  singleUser: undefined,
  message: undefined,
  suggestedaccounts: [],
  userFollow: undefined,
  followers: [],
  followings: [],
  countries: [],
  address: undefined,
  addresses: [],
  chatuserlist: [],
  chatusersearch: [],
  applyAffiliator: undefined,
  gigTermsAccept: undefined,
  businesses:[],
  kyc:undefined,
});

const getters = {
  allLogin: (state) => state.logins,
  allRegister: (state) => state.registrations,
  allVerify: (state) => state.verifications,
  allLoadingAuth: (state) => state.loadingAuth,
  allUser: (state) => state.users,
  allAffiliation: (state) => state.affiliation,
  allCsrfToken: (state) => state.csrfToken,
  allUserSingle: (state) => state.singleUser,
  allMessage: (state) => state.message,
  allSuggestedAccount: (state) => state.suggestedaccounts,
  allUserFollow: (state) => state.userFollow,
  allFollowers: (state) => state.followers,
  allFollowings: (state) => state.followings,
  allCountry: (state) => state.countries,
  allSingleAddress: (state) => state.address,
  allAddresses: (state) => state.addresses,
  allChatUser: (state) => state.chatuserlist,
  allChatUserSearch: (state) => state.chatusersearch,
  allApplyAffiliator: (state) => state.applyAffiliator,
  allGigTermsAccept:(state)=>state.gigTermsAccept,
  allBusinesses:(state)=>state.businesses,
  allKyc:(state)=>state.kyc,
};

const actions = {
  async getBusinesses({ commit },data) {
    try {
      const response = await Axios.post(baseUrl + "business/searchBusiness", data,{
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("setBusinesses", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getGigTermsAccept({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "user/gigTermsStatus", {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("setGigTerms", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postGigTermsAccept({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "user/gigTermsAccept", {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("setGigTerms", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postAppluAffiliator({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "user/applyAffiliator", data, {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("setApplyAsAffiliator", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async userChatSearch({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "chat/chatSearch", data, {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("setChatSearch", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async forgetPassword({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/forgetPassword", data, {
        headers: {
          "CSRF-TOKEN": this.state.csrfToken,
        },
      });
      if (response.status === 200) {
        console.log('data', data)

        commit("setVerifyData", data);
        router.push(`/verify-otp/user`);
      }
    } catch (error) {

      console.log(error);
      commit("setVerifyData", error.response);
      setTimeout(() => {
        commit("setVerifyData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async verifyForgetPassword({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.put(baseUrl + "user/forgetPasswordVerify/", data, {
        headers: {
          "CSRF-TOKEN": this.state.csrfToken,
        },
      });
      if (response.status === 200) {
        console.log('data', data)
        router.push(`/login/user`);
      }
    } catch (error) {

      console.log(error);
      commit("setVerifyData", error.response);
      setTimeout(() => {
        commit("setVerifyData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async updateUserProfile({ commit }, data) {
    try {
      const response = await Axios.put(baseUrl + "user/updateUserProfile", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("singleUserSet", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async login({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/login", data, {
        headers: {
          "CSRF-TOKEN": this.state.csrfToken,
        },
      });
      if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(response.data));
        router.push(`/`);
        commit("setLoginData", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setLoginData", error.response);
      setTimeout(() => {
        commit("setLoginData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async socialLogin({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/socialLogin", data, {
        headers: {
          "CSRF-TOKEN": this.state.csrfToken,
        },
      });
      if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(response.data));
        router.push(`/`);
        commit("setLoginData", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setLoginData", error.response);
      setTimeout(() => {
        commit("setLoginData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },

  async verify({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.put(baseUrl + "user/verify", data);
      if (response.status === 200) {
        commit("setVerifyData", response.data);
        router.push("/");
        localStorage.removeItem("emailAuth");
      }
    } catch (error) {
      console.log(error);
      commit("setVerifyData", error.response);
      setTimeout(() => {
        commit("setVerifyData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async register({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/register", data);

      if (response.status === 201) {
        router.push("/verify/user");

        localStorage.setItem("emailAuth", data.email);
      }
      commit("setRegisterData", { data: response.data.results.message });
    } catch (error) {
      commit("setRegisterData", error.response);
      setTimeout(() => {
        commit("setRegisterData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async registerAdmin({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/registerByAdmin", data);

      commit("setRegisterData", { data: response.data.results.message });
    } catch (error) {
      commit("setRegisterData", error.response);
      setTimeout(() => {
        commit("setRegisterData", []);
      }, 3000);
    }
    location.reload();
    this.dispatch("loadingNotAuth");
  },

  async logout({ commit }) {
    try {
      router.push("/login/user");
      localStorage.clear();
    } catch (error) {
      console.log(error);
    }

    commit("setLoginData", []);
  },
  getLoginData({ commit }, data) {
    commit("setLoginData", JSON.parse(data));
  },
  async getUsersProfile({ commit }) {
    try {
      if (process.browser) {
        const response = await Axios.get(baseUrl + "user/userProfile", {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        });
        if (response.status === 200) {
          commit("singleUserSet", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getOtherUsersProfile({ commit }, data) {
    try {
      if (process.browser) {
        const response = await Axios.post(baseUrl + "user/userprofile", { userHandle: data }, {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        });
        if (response.status === 200) {
          commit("singleUserSet", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getUsers({ commit }, data) {
    try {
      if (process.browser) {
        const response = await Axios.post(baseUrl + "user/users", data, {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        });
        if (response.status === 200) {
          commit("setUserData", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getAffiliation({ commit }, data) {
    try {
      if (process.browser) {
        const response = await Axios.post(baseUrl + "user/affiliations", data, {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        });
        if (response.status === 200) {
          commit("setAffiliationData", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async loadingAuth({ commit }) {
    commit("loadingAuthSet", true);
  },
  async loadingNotAuth({ commit }) {
    commit("loadingNotAuthSet", false);
  },
  async getCSRFToken({ commit }) {
    try {
      const response = await Axios.create({ withCredentials: true }).get(
        `${baseUrl}csrftoken/request`
      );
      console.log(Axios.defaults);
      if (response.status == 200) {
        commit("setCSRFToken", response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getUserProfile({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}user/userprofile/${data}`);
      if (response.status == 200) {
        commit("singleUserSet", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async changeAffiliationStatus({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}user/affiliationStatusControl`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        }
      );
      if (response.status == 200) {
        commit("singleUserSet", response.data);
        commit("setMessage", response.data.message);
      }
    } catch (error) {
      console.log(error);
      commit("setMessage", error.data.message);
    }
  },
  async getSuggestedAccounts({ commit }, data) {
    try {
      const repsonse = await Axios.post(`${baseUrl}user/suggestaccounts`, data);
      if (repsonse.status == 200) {
        commit("setSuggestedAccount", repsonse.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async followUser({ commit }, data) {
    try {
      const repsonse = await Axios.post(`${baseUrl}user/followUser`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (repsonse.status == 200) {
        commit("setUserFollow", repsonse.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async unfollowUser({ commit }, data) {
    try {
      const repsonse = await Axios.post(`${baseUrl}user/removeFollower`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (repsonse.status == 200) {
        commit("setUserFollow", repsonse.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getFollowers({ commit }, data) {
    try {
      if (process.browser) {
        const response = await Axios.post(baseUrl + "user/listOfFollowersUser", data, {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        });
        if (response.status === 200) {
          commit("setFollowers", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getFollowings({ commit }, data) {
    try {
      if (process.browser) {
        const response = await Axios.post(baseUrl + "user/listOfFollowingsUser", data, {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("token")).results.token,
          },
        });
        if (response.status === 200) {
          commit("setFollowings", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getCountries({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "analytics/country");
      if (response.status == 200) {
        commit("setCountries", response.data.results)
      }
    } catch (error) {
      console.log(error);
    }
  },
  async addAddress({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "user/addAddress", data, {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 201 || response.status == 200) {
        commit("setAddress", response.data)
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getAddress({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "user/addresses", data, {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("setAddresses", response.data)
      }
    } catch (error) {
      console.log(error);
    }
  },
  async chatuserlist({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "chat/chatUserList", {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if (response.status == 200) {
        commit("setChatUserList", response.data)
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postKyc({ commit }, data) {
    try{
      const response = await Axios.post(baseUrl + "user/kycform", data, {
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("token")).results.token,
        },
      });
      if(response.status == 200){
        commit("setKyc", response.data)
      }
    }catch(error){
      console.log(error)
      commit("setKyc", error.response.data)
    }
  },
};

const mutations = {
  setLoginData: (state, user) => (state.logins = user),
  setMessage: (state, data) => (state.message = data),
  setDashboard: (state, user) => (state.dashboard = user),
  setUserData: (state, user) => (state.users = user),
  setAffiliationData: (state, user) => (state.affiliation = user),
  setCSRFToken: (state, data) => (state.csrfToken = data),
  setRegisterData: (state, user) => (state.registrations = user),
  setVerifyData: (state, user) => (state.verifications = user),
  loadingAuthSet: (state, loading) => (state.loadingAuth = loading),
  singleUserSet: (state, loading) => (state.singleUser = loading),
  setUserFollow: (state, loading) => (state.userFollow = loading),
  loadingNotAuthSet: (state, loading) => (state.loadingAuth = loading),
  setSuggestedAccount: (state, loading) => (state.suggestedaccounts = loading),
  setFollowers: (state, data) => (state.followers) = data,
  setFollowings: (state, data) => (state.followings) = data,
  setCountries: (state, data) => (state.countries) = data,
  setAddress: (state, data) => (state.address) = data,
  setAddresses: (state, data) => (state.addresses) = data,
  setChatUserList: (state, data) => (state.chatuserlist) = data,
  setChatSearch: (state, data) => (state.chatusersearch) = data,
  setApplyAsAffiliator: (state, data) => (state.applyAffiliator) = data,
  setGigTerms: (state, data) => (state.gigTermsAccept) = data,
  setBusinesses: (state, data) => (state.businesses) = data,
  setKyc: (state, data) => (state.kyc) = data,
};

export default { state, getters, actions, mutations };
