<template>
  <section class="main-section">
    <v-container class="container-custom-lg">
      <v-row>
        <v-col class="pt-0">
          <v-main>
            <router-view :key="$route.fullPath"></router-view>
          </v-main>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Frontend",
  methods: {
    ...mapActions(["getLoginData"]),
  },
  created() {
    if (this.$route.query.token != undefined) {
      localStorage.setItem(
        "token",
        JSON.stringify({
          results: {
            token: this.$route.query.token,
          },
        })
      );
    }
    if (localStorage.getItem("token") != undefined) {
      this.getLoginData(localStorage.getItem("token"));
    } else {
      this.$router.push("/login/user");
    }
  },
};
</script>

<style lang="scss" scoped>
.leftMenu {
  position: relative;
}
.popup {
  &-back {
    background: rgba($color: rgb(110, 110, 110), $alpha: 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
  }
  &-inside {
    background: white;
    width: 600px;
    position: relative;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
  }
}
</style>
